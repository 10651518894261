<template>
    <div class="anleftnav darkBack">
        <div>
            <p>注册人数</p>
            <div>
                <countTo :startVal='0' :endVal="list.venueRegNum" :duration='3000'></countTo>
            </div>
        </div>
        <div>
            <p>在线运动人数</p>
            <div>
                <countTo :startVal='0' :endVal="list.online_total" :duration='3000'></countTo>
            </div>
        </div>
        <div>
            <p>今日运动人数</p>
            <div>
                <countTo :startVal='0' :endVal="list.day_sportsNum" :duration='3000'></countTo>
            </div>
        </div>
        <div>
            <p>总运动人数</p>
            <div>
                <countTo :startVal='0' :endVal="list.all_sportsNum" :duration='3000'></countTo>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    data () {
        return {
            list:""
        }
    },
    mounted() {
        console.log(window.jianshenfangData)
        let data={}
        let url = window.appHost+"/api/v1/screen/Venue/vital?businessid="+window.jianshenfangData.id
        let result = window.ajaxRequest(url,JSON.stringify(data),"post")
        // console.log(result)
        this.list=result.msg
    },
}
</script>

<style scoped>
.anleftnav{
    width:5.94rem;
    height:2.86rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    padding: 0.2rem;
    color:#A8DAEB;
    font-size: 0.14rem;
    text-align: center;
}
.anleftnav>div{
    width:2.44rem;
    height:0.9rem;
    background: url('../../../assets/images/borderBack.png') no-repeat;
    background-size:100% 100%;
    padding-top: 0.2rem;
    box-sizing: border-box;
}
.anleftnav>div>div{
    color:#20D7FE;
    font-size: 0.22rem;
}
</style>